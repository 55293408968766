<template>
  <ag-card-container
    title="Macro KPI"
    fluid
    no-content-padding
  >
    <template #left>
      <v-btn
        :disabled="!macroKpiResult || loadingData"
        color="success"
        fab
        x-small
        :loading="loadingExport"
        @click="exportExcel"
      >
        <v-icon>file_download</v-icon>
      </v-btn>
      <v-menu
        v-model="settingsMenu"
        :close-on-content-click="false"
        :nudge-width="200"
        offset-y
      >
        <template v-slot:activator="{ on, attrs }">
          <v-btn
            class="ml-2"
            color="accent"
            fab
            x-small
            v-bind="attrs"
            v-on="on"
          >
            <v-icon>settings</v-icon>
          </v-btn>
        </template>

        <v-card>
          <v-list>
            <v-list-item>
              <v-list-item-action>
                <v-switch
                  v-model="previousPeriod"
                  inset
                  hide-details
                />
              </v-list-item-action>
              <v-list-item-title>Comparer les périodes précédentes</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-card>
      </v-menu>
    </template>
    <v-card class="text-center">
      <v-progress-circular
        v-if="loadingData"
        style="text-center"
        class="my-8"
        :size="80"
        width="8"
        indeterminate
        color="primary"
      />
    </v-card>
    <v-tabs
      v-if="macroKpiResult"
      v-model="tab"
      background-color="primary"
      color="primary"
      :slider-size="5"
      slider-color="accent"
      grow
    >
      <v-tab
        v-for="item in macroKpiResult"
        :key="item.type"
        eager
        v-text="item.typeName"
      />
    </v-tabs>
    <v-tabs-items v-model="tab">
      <v-tab-item
        v-for="item in macroKpiResult"
        :key="item.type"
        eager
      >
        <macro-kpi :key="previousPeriod" :previous-period="previousPeriod" :value="item" />
      </v-tab-item>
    </v-tabs-items>
    <!-- RouterView for modals -->
    <router-view :loading-data="loadingData" :macro-kpi-data="macroKpiResult" />
  </ag-card-container>
</template>

<script>
import AgCardContainer from 'src/components/common/AgCardContainer'
import { mapActions } from 'vuex'
import Repositories from 'src/repositories'
import { createExcelFile } from 'src/services/drawExcelService'
import MacroKpi from '../components/MacroKpi'

export default {
  name: 'MacroKpiContainer',
  components: {
    AgCardContainer,
    MacroKpi,
  },
  data: () => ({
    tab: null,
    macroKpiResult: null,
    loadingData: false,
    loadingExport: false,
    previousPeriod: false,
    settingsMenu: false,
  }),
  computed: {
    tabExcelExport () {
      return [
        {
          key: 'finish',
          name: 'Production',
        },
        {
          key: 'rejected',
          name: 'Rebut',
        },
        {
          key: 'retouched',
          name: 'Retouche',
        },
      ]
    },
  },
  mounted () {
    this.fetchData()
  },
  methods: {
    ...mapActions('ui', {
      showErrorNotification: 'showErrorNotification',
    }),
    async fetchData () {
      try {
        this.loadingData = true
        this.macroKpiResult = await Repositories.part.getMacroKpi()
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingData = false
      }
    },
    async exportExcel () {
      try {
        this.loadingExport = true
        const dataArray = []
        // eslint-disable-next-line no-restricted-syntax
        for (const dateRange of this.macroKpiResult) {
          for (const tab of this.tabExcelExport) {
            const exportConfig = {
              columns: [],
              view: [{ state: 'frozen', ySplit: 1 }],
              sheetName: `${dateRange.typeName}_${tab.name}`,
            }
            const tempData = []
            for (const row of dateRange.current[tab.key]) {
              const alreadyExist = tempData.findIndex(item => item.name === row.name)
              if (alreadyExist > -1) {
                tempData[alreadyExist].current = row.count
              }
              else {
                tempData.push({
                  name: row.name,
                  current: row.count,
                })
              }
            }
            for (const row of dateRange.last[tab.key]) {
              const alreadyExist = tempData.findIndex(item => item.name === row.name)
              if (alreadyExist > -1) {
                tempData[alreadyExist].last = row.count
              }
              else {
                tempData.push({
                  name: row.name,
                  last: row.count,
                })
              }
            }
            for (const row of dateRange.lastTwo[tab.key]) {
              const alreadyExist = tempData.findIndex(item => item.name === row.name)
              if (alreadyExist > -1) {
                tempData[alreadyExist].lastTwo = row.count
              }
              else {
                tempData.push({
                  name: row.name,
                  lastTwo: row.count,
                })
              }
            }
            let subtract = 0
            // eslint-disable-next-line new-cap
            const tempDateCurrent = this.getFormatDate(dateRange.type, new this.moment().subtract(subtract, dateRange.type).startOf(dateRange.type).add(6, 'h'))
            subtract = 1
            // eslint-disable-next-line new-cap
            const tempDateLast = this.getFormatDate(dateRange.type, new this.moment().subtract(subtract, dateRange.type).startOf(dateRange.type).add(6, 'h'))
            subtract = 2
            // eslint-disable-next-line new-cap
            const tempDateLastTwo = this.getFormatDate(dateRange.type, new this.moment().subtract(subtract, dateRange.type).startOf(dateRange.type).add(6, 'h'))

            exportConfig.columns.push({ header: 'Type de référence', key: 'name' })
            exportConfig.columns.push({ header: tempDateLastTwo, key: 'lastTwo' })
            exportConfig.columns.push({ header: tempDateLast, key: 'last' })
            exportConfig.columns.push({ header: tempDateCurrent, key: 'current' })

            dataArray.push({ dataToExport: tempData, config: exportConfig })
          }
        }

        await createExcelFile(dataArray, 'Macro_KPI')
      }
      catch (error) {
        this.showErrorNotification(Repositories.parseErrorMessage(error))
      }
      finally {
        this.loadingExport = false
      }
    },
    getFormatDate (type, date) {
      switch (type) {
        case 'day':
          return `${date.format('LL')}`
        case 'week':
          return `S${date.format('w')}`
        case 'month':
          return `${date.format('MMMM')}`
        case 'year':
          return `${date.format('Y')}`
        default:
          return ''
      }
    },
  },
}
</script>
<style scoped>
.v-menu__content{
  z-index: 9999 !important;
}
</style>
