<template>
  <v-container fluid>
    <v-row>
      <v-col class="pb-0">
        <v-card outlined>
          <v-card-text class="text-center text-subtitle-1">
            <span class="date-range-text">
              {{ currentPeriodDateRange }}
            </span>
            {{ value.type === 'day' ? 'comparé au' : 'comparé à' }}
            <span class="date-range-text">
              {{ previousPeriodDateRange }}
            </span>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        xs="12"
        sm="6"
        md="4"
      >
        <part-type-donut
          v-if="value"
          :data-list="currentData.finish"
          :sum="currentData.finishSum"
          title="Production"
          :details-url="`${value.type}/finish`"
        />
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="4"
      >
        <part-type-donut
          v-if="value"
          :data-list="currentData.rejected"
          :sum="currentData.rejectedSum"
          title="Rebuts"
          :details-url="`${value.type}/rejected`"
        />
      </v-col>
      <v-col
        xs="12"
        sm="6"
        md="4"
      >
        <part-type-donut
          v-if="value"
          :data-list="currentData.retouched"
          :sum="currentData.retouchedSum"
          title="Retouches"
          :details-url="`${value.type}/retouched`"
        />
      </v-col>
    </v-row>
  </v-container>
</template>
<script>
import PartTypeDonut from './PartTypeDonut'

export default {
  name: 'MacroKpi',
  components: {
    PartTypeDonut,
  },
  props: {
    value: {
      type: Object,
      default: null,
    },
    previousPeriod: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentData () {
      if (this.previousPeriod) return this.value.last
      return this.value.current
    },
    currentPeriodDateRange () {
      if (this.value) {
        let subtract = 0
        if (this.previousPeriod) {
          subtract = 1
        }
        // eslint-disable-next-line new-cap
        const startOfPeriod = new this.moment().subtract(subtract, this.value.type).startOf(this.value.type).add(6, 'h')
        return this.getFormatDate(this.value.type, startOfPeriod)
      }
      return ''
    },
    previousPeriodDateRange () {
      if (this.value) {
        let subtract = 1
        if (this.previousPeriod) {
          subtract = 2
        }
        // eslint-disable-next-line new-cap
        const startOfLastPeriod = new this.moment().subtract(subtract, this.value.type).startOf(this.value.type).add(6, 'h')
        return this.getFormatDate(this.value.type, startOfLastPeriod)
      }
      return ''
    },
  },
  methods: {
    getFormatDate (type, date) {
      switch (type) {
        case 'day':
          return `${date.format('LL')}`
        case 'week':
          return `S${date.format('w')}`
        case 'month':
          return `${date.format('MMMM')}`
        case 'year':
          return `${date.format('Y')}`
        default:
          return ''
      }
    },
  },
}
</script>
<style scoped>
.date-range-text {
  font-weight: bold;
  font-size: 1.30rem;
}
</style>
