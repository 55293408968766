<template>
  <v-card outlined max-width="500px" style="margin: auto">
    <v-card flat>
      <v-card-text v-if="sum" class="text-center">
        <h1>
          {{ title }} : {{ chartdata.data.datasets[0].sum }}
        </h1>
        <v-icon size="35" v-text="trendIcon" />
        <h3>
          {{ sum.currentTotal }} ({{ sum.lastTotal }})
          {{ sum.evolution ? sum.evolution.toFixed(1) : '-' }}%
        </h3>
      </v-card-text>
    </v-card>
    <v-divider />
    <v-card-text>
      <donut v-if="dataReady" :chartdata="chartdata" />
      <h1 v-else-if="!dataList.length" class="text-center" style="width: 100%">
        Aucunes données
      </h1>
    </v-card-text>
    <v-card-actions>
      <v-spacer />
      <v-btn
        color="primary"
        :to="detailsUrl"
        append
        outlined
      >
        Détails des références
      </v-btn>
      <v-spacer />
    </v-card-actions>
  </v-card>
</template>
<script>
import Chart from 'chart.js'
import Donut from './Donut'

export default {
  name: 'PartTypeDonut',
  components: {
    Donut,
  },
  props: {
    dataList: {
      type: Array,
      default: () => ([]),
    },
    sum: {
      type: Object,
      default: () => (null),
    },
    title: {
      type: String,
      default: '',
    },
    detailsUrl: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    dataReady: false,
    chartdata: {
      type: 'doughnut',
      data: {
        labels: [],
        datasets: [{
          sum: 0,
          otherData: [],
          data: [],
          backgroundColor: [],
          borderColor: [],
          borderWidth: 0,
        }],
      },
      options: {
        responsive: true,
        legend: {
          position: 'bottom',
        },
        tooltips: {
          callbacks: {
            label (tooltipItem, data) {
              let label = data.labels[tooltipItem.index] || ''
              const tempData = data.datasets[tooltipItem.datasetIndex].otherData[tooltipItem.index]
              const symbol = tempData.evolution > 0 ? '+' : ''
              label += `: ${tempData.count} (${tempData.lastCount}) ${symbol}${Math.round(tempData.evolution)}%`
              return label
            },
          },
        },
      },
      plugins: [{
        afterDatasetsDraw (chart) {
          const { ctx } = chart
          chart.data.datasets.forEach((dataset, i) => {
            const meta = chart.getDatasetMeta(i)
            if (!meta.hidden) {
              let total = 0
              meta.data.forEach((element, index) => {
                if (!element.hidden) {
                  total += dataset.data[index]
                }
              })
              meta.data.forEach((element, index) => {
                if (
                // eslint-disable-next-line no-mixed-operators
                  (dataset.data[index] / total * 100).toFixed(0) > 2 && element.hidden === false
                ) {
                  ctx.fillStyle = 'rgb(0, 0, 0)'
                  const fontSize = 16
                  const fontStyle = 'normal'
                  const fontFamily = 'Roboto'
                  ctx.font = Chart.helpers.fontString(fontSize, fontStyle, fontFamily)

                  // eslint-disable-next-line no-mixed-operators
                  const dataString = `${(dataset.data[index] / total * 100).toFixed(0).toString()}%`

                  ctx.textAlign = 'center'
                  ctx.textBaseline = 'middle'
                  const position = element.tooltipPosition()
                  ctx.fillText(dataString, position.x, position.y)
                }
              })
              dataset.sum = total
            }
          })
        },
      }],
    },
  }),
  computed: {
    trendIcon () {
      if (this.sum.currentTotal > this.sum.lastTotal) return 'trending_up'
      if (this.sum.currentTotal < this.sum.lastTotal) return 'trending_down'
      return 'trending_flat'
    },
  },
  mounted () {
    this.initData()
  },
  methods: {
    initData () {
      if (this.dataList && this.dataList.length) {
        this.chartdata.data.labels = []
        this.chartdata.data.datasets[0].data = []
        this.chartdata.data.datasets[0].backgroundColor = []
        this.dataList = this.dataList.sort((a, b) => b.count - a.count)
        for (const item of this.dataList) {
          this.chartdata.data.labels.push(item.name)
          this.chartdata.data.datasets[0].data.push(item.count)
          this.chartdata.data.datasets[0].backgroundColor.push(this.getColor(item.name))
        }
        this.chartdata.data.datasets[0].otherData = this.dataList
        this.dataReady = true
      }
    },
    getColor (name) {
      switch (name) {
        case 'Petit module':
          return 'rgba(54, 162, 235, 0.4)'
        case 'Grand module':
          return 'rgba(132, 54, 235, 0.4)'
        case 'Faisceau':
          return 'rgba(255, 99, 132, 0.4)'
        case 'Bobine':
          return 'rgba(255, 206, 86, 0.4)'
        case 'Collodion':
          return 'rgba(75, 192, 192, 0.4)'

        default:
          return 'rgba(54, 162, 235, 0.4)'
      }
    },
  },
}
</script>
