<template>
  <canvas ref="donutChartjs" width="200" height="200" />
</template>
<script>
import Chart from 'chart.js'

export default {
  props: {
    chartdata: {
      type: Object,
      default: null,
    },
  },
  data: () => ({
    chart: null,
  }),
  async mounted () {
    const ctx = this.$refs.donutChartjs
    // eslint-disable-next-line no-unused-vars
    this.chart = new Chart(ctx, this.chartdata)
  },
  async beforeDestroy () {
    if (this.chart) {
      await this.chart.destroy()
    }
  },
}
</script>
